
import {defineComponent, ref} from 'vue';
import BtnCreateModal from '@/components/pitchMoments/BtnCreateModal.vue';
import Card from '@/components/pitchMoments/Card.vue';
import PitchMoment from '@/models/PitchMoment';
import {Portal} from 'portal-vue';

export default defineComponent({
    components: {
        BtnCreateModal,
        Card,
        Portal,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    setup(props) {
        return {
            pitchMoment: ref<PitchMoment>(new PitchMoment({
                id: +props.id,
            })),
        };
    },
    created() {
        this.pitchMoment.fetch();
    },
    methods: {
        updatePitchMoment(pitchMoment: PitchMoment) {
            this.pitchMoment.fill(pitchMoment.attributes);
        },
    },
});
