
import {PropType, defineComponent} from 'vue';
import PitchMoment from '@/models/PitchMoment';
import PitchMomentForm from '@/components/pitchMoments/Form.vue';

export default defineComponent({
    components: {
        PitchMomentForm,
    },
    props: {
        pitchMoment: {
            type: Object as PropType<PitchMoment>,
            required: true,
        },
    },
    emits: [
        'updated-pitch-moment',
    ],
});
