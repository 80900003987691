import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center h-100" }
const _hoisted_2 = { class: "ms-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_BtnCreateModal = _resolveComponent("BtnCreateModal")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_Card = _resolveComponent("Card")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_RouterLink, {
            class: "btn px-0",
            to: {name: 'programs.index'}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Fa, { icon: "arrow-left" })
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('pitchMoments.edit.title')), 1),
          _createVNode(_component_BtnCreateModal, { class: "ms-auto" })
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_WaitForResource, { resource: _ctx.pitchMoment }, {
      default: _withCtx(() => [
        _createVNode(_component_Card, {
          "pitch-moment": _ctx.pitchMoment,
          onUpdatedPitchMoment: _ctx.updatePitchMoment
        }, null, 8, ["pitch-moment", "onUpdatedPitchMoment"])
      ]),
      _: 1
    }, 8, ["resource"])
  ]))
}