import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header py-12 px-16 border-bottom" }
const _hoisted_3 = { class: "card-body py-12 px-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PitchMomentForm = _resolveComponent("PitchMomentForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.pitchMoment.name), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PitchMomentForm, {
        "edit-pitch-moment": _ctx.pitchMoment,
        onSavedPitchMoment: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updated-pitch-moment', $event)))
      }, null, 8, ["edit-pitch-moment"])
    ])
  ]))
}